/*
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
*/
@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* 
If you see "Unknown at rule @tailwind" install Tailwind CSS Intellisense
and change VSCode settings:
    "files.associations": {
        "*.tailwind.css": "tailwindcss"
    }
*/
@tailwind base;
@tailwind components;
@tailwind utilities;

/** Setting base font size */

html {
  font-size: 16px;
  overflow: hidden;
  background-color: theme("colors.neutral");
  font-family:'Alexandria', sans-serif;
}

/** Portals */
#notifications-root {
  z-index: 150;
}
/** Removes chrome's autofill styling */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-background-clip: text;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
  background-clip: text;
  background-color: #fff;
}
@keyframes wavepulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0, 98, 255, 1);
  }

  70% {
    box-shadow: 0 0 0 15px rgba(0, 98, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(0, 98, 255, 0);
  }
}
@keyframes fade-in-height {
  0% {
    opacity: 0;
    max-height: 0px;
  }
  100% {
    opacity: 1;
    max-height: 150px;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes grow {
  0% {
    flex-grow: 0.00001;
    overflow: hidden;
  }

  100% {
    flex-grow: 1;
    overflow: hidden;
  }
}
@keyframes down-up {
  0% {
    opacity: 0;
    transform: translateY(2%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes down-up-strong {
  0% {
    opacity: 0;
    transform: translateY(15%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
/** Fixes google maps pegman not showing */
.gm-svpc div img {
  position: relative !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Scrollbar appearing on hover */
.hover-scrollbar {
  overflow-y: auto;
  visibility: hidden;
  scrollbar-gutter: stable;
}
.hover-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 0px;
}

.hover-scrollbar::-webkit-scrollbar-track {
  display: none;
}

.hover-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: gray;
}
.hover-scrollbar > *,
.hover-scrollbar:hover,
.hover-scrollbar:focus {
  visibility: visible;
}
.hover-scrollbar:hover {
  transition: visibility 0.2s;
}
/* Simple mini scrollbar */
.subtle-scrollbar {
  overflow-y: auto;
  scrollbar-gutter: stable;
}
.subtle-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 0px;
}
.subtle-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: theme(colors.neutral);
}

.subtle-scrollbar::-webkit-scrollbar-track {
  background-color: theme(colors.primary);
}
