.leaflet-tooltip:before {
  all: unset;
}
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.leaflet-container{
  user-select:none;
}
.leaflet-tooltip {
    background-color: unset;
    color: #fff;
    font-size:14px;
    font-weight:600;
    width:35px;
    height:35px;
    border:0;
    box-shadow:none;
}
div{
  image-rendering:pixelated;
}
/* Outer wrapper */
.leaflet-popup{
	margin-bottom: 40px;
}
/* Inner wrapper */
.leaflet-popup-content-wrapper{
  background:theme("colors.neutral2");
  
  color:theme("colors.contrast");
}
/* Close button */
.leaflet-popup-close-button{
  display:none;
}
/* Arrow tip */
.leaflet-popup-tip{
  background:theme("colors.neutral2");
}
.leaflet-container{
  background-color:theme("colors.neutral")
}